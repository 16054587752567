import "./Contact.css";
import { FaEnvelope, FaPhone, FaUserTie } from "react-icons/fa";
import { InlineWidget } from "react-calendly";

function Contact() {
  return (
    <div className="section-c">
      <h2 className="text-center py-3">Contattaci o Prenota una Consulenza Gratuita!</h2>
      <div className="container-c">
        <div className="col-left-c">
        <InlineWidget url="https://calendly.com/giulian993" styles={{ height: '500px'}}/>
        </div>
        <div className="col-right-c">
          <p>
            <FaEnvelope /> <span style={{ fontWeight: 'bold' }}>Email:</span> info@digitalevo.it
          </p>
          <p>
            <FaPhone /> <span style={{ fontWeight: 'bold' }}>Telefono:</span> 3486357263
          </p>
          <p>
            <FaUserTie /> <span style={{ fontWeight: 'bold' }}>P.iva:</span> 04903210237
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;