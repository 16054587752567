import Contact from './Contact';
import Footer from './Footer';
import Navbar from './Navbar';
import Section from './Section';
import Video from './Video';

function Home() {
    return <div>
        <Navbar />
        <Video />
        <Section />
        <Contact />
        <Footer />
    </div>
}

export default Home;