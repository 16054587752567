import './Video.css';
import videoSrc from './Video.mp4';

function Video(props) {
  const { bgColor, marginTop } = props;

  return (
    <div
      className="video"
      style={{
        position: 'relative',
        top: `${marginTop}px`,
        backgroundColor: bgColor,
      }}
    >
      <video autoPlay loop muted className="video-player">
        <source src={videoSrc} type="video/mp4" />
      </video>
      <div className="video-text">
        <h1>Sviluppo e Soluzioni Digitali</h1>
      </div>
    </div>
  );
}

export default Video;
