import "./Footer.css";

function Footer() {
  return (
      <div className="footer">
        <div className="footer-content">
            <p>Devo &copy; 2023  |  <a href="/privacy">Informativa sulla Privacy</a></p>
          </div> 
        </div>
  );
}

export default Footer;