import "./Section.css";

function Section() {
  return (
    <div>
      <div className="section">
        <div className="container">
          <div className="col-left">
            <h1>Sviluppo Web</h1>
            <p>Siti Web Privati e Aziende</p>
            <p>Gestionali Personalizzati</p>
            <p>E-Commerce</p>
            <p>Grafica Foto e Video Edit</p>
          </div>
          <div className="col-right">
            <img src={process.env.PUBLIC_URL + "/imgsez1.png"} alt="Immagine sezione 1" />
          </div>
        </div>
      </div>
      <hr/>
      <div className="section">
        <div className="container">
          <div className="col-left">
            <h1>Automazione e A.I.</h1>
            <p>Pipelines ETL</p>
            <p>Web Scraping</p>
            <p>Inserimento e Acquisizione Dati</p>
            <p>Algoritmi di A.I.</p>
          </div>
          <div className="col-right">
            <img src={process.env.PUBLIC_URL + "/imgsez2.png"} alt="Immagine sezione 2" />
          </div>
        </div>
      </div>
      <hr/>
      <div className="section">
        <div className="container">
          <div className="col-left">
            <h1>Metaverso e Blockchain</h1>
            <p>Blockchain Private</p>
            <p>Sviluppo Smart Contract Personalizzati e Token</p>
            <p>Progetti Nft Personalizzati</p>
            <p>Progettazione e sviluppo di ambienti e mondi virtuali</p>
          </div>
          <div className="col-right">
            <img src={process.env.PUBLIC_URL + "/imgsez3.png"} alt="Immagine sezione 3" />
          </div>
        </div>
      </div>
      <hr/>
      <div className="section">
        <div className="container">
          <div className="col-left">
            <h1>Social Media Management</h1>
            <p>Creazione Contenuti - Testi, Foto, Video</p>
            <p>Analisi Profili e Consulenza</p>
            <p>Copywriting e Proofreading</p>
            <p>Seo e Readability</p>
          </div>
          <div className="col-right">
            <img src={process.env.PUBLIC_URL + "/imgsez4.png"} alt="Immagine sezione 4" />
          </div>
        </div>
      </div>
      <hr style={{ marginBottom: 0 }}/>
    </div>
  );
}

export default Section;