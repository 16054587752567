import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';
import { Link } from "react-scroll";
import logo from './logo.svg';

function NavbarComponent() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="/"><img
          src={logo}
          width="140"
          height="56"
          className="d-inline-block align-top"
          alt="Logo"
        /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="navbar-nav">
          <Nav.Link>
            <Link
              activeClass="active"
              to="section"
              spy={true}
              smooth={true}
              offset={-70}
              duration={400}
            >
              Servizi
            </Link>
          </Nav.Link>
          <Nav.Link>
            <Link
              activeClass="active"
              to="section-c"
              spy={true}
              smooth={true}
              offset={-70}
              duration={700}
            >
              Contatti
            </Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;