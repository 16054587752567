import "./Privacy.css"
import Navbar from './Navbar';

function Privacy() {
    return <div>
        <Navbar />
        <div className="privacy px-3">
            <h2 className="py-3">Privacy Digital Evo</h2>

            <p>Il presente articolo sulla privacy descrive come Digital Evo raccoglie, utilizza e protegge le informazioni personali dei visitatori del sito. Quando utilizzi il nostro sito, accetti di conformarti alla presente politica sulla privacy.</p>


            <h5>1. Raccolta delle Informazioni</h5>
            <p>Raccogliamo informazioni personali, come nome, cognome, indirizzo email e numero di telefono, solo quando ci viene fornita volontariamente dagli utenti interessati ai nostri servizi. Utilizziamo queste informazioni per rispondere alle richieste degli utenti, per fornire servizi personalizzati e per migliorare il nostro sito web.</p>

            <h5>2. Protezione delle Informazioni</h5>
            <p>Ci impegniamo a proteggere le informazioni personali degli utenti. Utilizziamo misure di sicurezza tecniche e organizzative per prevenire la perdita, la divulgazione o l'accesso non autorizzato alle informazioni personali degli utenti.</p>

            <h5>3. Condivisione delle Informazioni</h5>
            <p>Non condividiamo le informazioni personali degli utenti con terze parti, a meno che non sia richiesto dalla legge o necessario per fornire i servizi richiesti dagli utenti.</p>

            <h5>4. Modifiche alla Politica sulla Privacy</h5>
            <p>Ci riserviamo il diritto di modificare la presente politica sulla privacy in qualsiasi momento. Le modifiche saranno pubblicate sul nostro sito web.</p>

            <h5>5. Contattaci</h5>
            <p>Se hai domande o preoccupazioni sulla nostra politica sulla privacy, contattaci tramite il modulo di contatto sul nostro sito web.</p>

            <p>In sintesi, il presente articolo sulla privacy descrive come raccogliamo, utilizziamo e proteggiamo le informazioni personali degli utenti che ci contattano tramite il nostro sito web.</p>
        </div>
    </div>
}

export default Privacy;